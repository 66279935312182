import React from 'react';
import './ABOrders.scss';
import sideImg from './imgs/image-right.jpg';

const ABOrders = () => {
    return (
        <div className="content-item orders-container">
            <div>
                <h2>Placing an Order</h2>
                <br/>
                <p>We take orders of over the phone with the following requirements:</p>
                <ul>
                    <li>Order Size: 1 Dozen items or 1 whole Quiche minimum</li>
                    <li>Order Date: At least 48 hours in advance</li>
                    <li>All order times subject to availability</li>
                    <li>All order modifications must be done 24 hours in advance</li>
                    <li>Orders must be picked up on same day.</li>
                    <ul><li> No refunds or adjustments will be made if the order is not collected.</li></ul>
                </ul>
                <p>We need the following to place your order:</p>
                <ul>
                    <li>Name for the Order</li>
                    <li>Desired Items</li>
                    <li>Pickup Date &amp; Time</li>
                    <li>Credit Card Payment Details</li>
                </ul>
                <p>To place an order, call us with the above details at:</p>
                <ul>
                    <li>397 Arguello: <a href="tel:1-415-750-9460">415-750-9460</a></li>
                    <li>87 McAllister: <a href="tel:1-415-926-5155">415-926-5155</a></li>
                </ul>
            </div>
            <div>
                <img className="orders-img" src={sideImg} alt="Tasty Delights" />
            </div>
        </div>
        
    )
}

export default ABOrders