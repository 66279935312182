import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const MenuListing = (props) => {
  return (
    <Container className="no-margin no-padding">
      <Row className="pt-4">
        <Col>
          <h2>{props.title}</h2>
        </Col>
      </Row>
      {props.items.map((item, index) => (
        <Row key={index} className="pt-2">
          <Col xs={9}>
            <div>{item[0]}</div>
          </Col>
          <Col xs={3} className={`${props.center && "center-text"}`}>
            <div>{item[1]}</div>
          </Col>
          { item[2] && <Row>
          <Col style={{margin:"0 .8rem"}}>  <div style={{fontSize:"12px"}}><i>{item[2]}</i></div> </Col>
          </Row>
        }
        </Row>
        


      ))}
    </Container>
  );
};

const ABMcAllisterMenu = () => {
  const menu = {
    left: [{
      title: "Daily Pastries",
      items: [
        ["Plain Croissant", "4.75"],
        ["Chocolate Croissant", "5.75"],
        ["Ham & Cheese Croissant", "6.75"],
        ["Vegetarian Galette", "6"],
        // ["Bacon Garlic Galette", "6.25"],
        ["Almond Croissant", "5.75"],
        ["Chocolate Almond Croissant", "6.5"],
        ["Blackberry Almond Hazelnut Croissant", "6.5"],
        ["Apple Turnover", "6.5"],
        ["Kouign Amann", "5"],
        ["Raisin Kouign", "5.75"],
        ["Morning Bun", "5"],
        ["Savory Scone", "5.25"],
        ["Cranberry Coconut Scone", "4.25"],
        ["Currant Scone", "4.25"],
        ["Palmier", "5"],
        ["Chocolate Chip Cookie", "3.75"],
        //["Oatmeal Raisin Cookie", "3.75"],
      ],
    },],
    right: [{
      title: "Coffee Bar",
      items: [
        ["Espresso", "3"],
        ["Espresso Macchiato", "3.75"],
        ["Cortado", "3.75"],
        ["Cappuccino", "4"],
        ["Latte", "4.5 / 5"],
        ["Vanilla Latte", "5 / 5.5"],
        ["Mocha", "5.5 / 6"],
        ["Drip", "2.75 / 3.25"],
        ["Au Lait", "3.75 / 4.25"],
        ["Americano", "3.5"],
        ["Cold Brew", "4/4.5"],
        ["Tea", "2.75"],
        ["Tea Latte", "3.75"],
      ],
    }],
    center: [
      {
        title: "Small Bites",
        items: [
          ["Financier", "12"],
          ["Shortbread Cookies", "10"],
          ["Chocolate Shortbread Cookies", "12"],
          ["Caramelized Puff Pastry", "10"],
        ],
      },{
        title: "Quiche",
        items: [
          ["Bacon Onion", "9"],
          ["Mushroom", "9"],
          ["Refrigerated Whole Bacon Onion", "72"],
          ["Refrigerated Whole Mushroom", "72"]

        ],
      },
      {
        title: "Bread",
        items: [
          ["Baguette", "5"],
          ["Apricot Bostock", "5.75"],
          ["Blackberry Bostock", "5.75"],
          ["Sourdough Country Bread", "7"],
          ["Walnut Bread", "9"],
          //["Sourdough Brioche", "8"],
        ],
      },
      {
        title: "Sandwhiches",
        items: [
          ["The Parisian", "12", "Butter, Ham, Sea Salt"],
          ["The Londoner", "13", "Butter, Ham, Sea Salt, Orange Marmalade"],
          ["The Mediterranean", "14", "Homemade Garlic Aioli, Ham, Oven Roasted Tomatoes, Sea Salt"],
          ["The Vegetarian", "13", "Homemade Garlic Aioli, Roasted Cauliflower, Oven Roasted Tomatoes, Arugula, Sea Salt"],
          ["** Add Aged Comté Cheese", "+2"],
        ]
      }
    ],
  };

  return (
    <div className="grid-container grid-container--fit">
      <div className="grid-element">
        {menu.left.map((section, item) => (
          <MenuListing
            key={item}
            title={section.title}
            items={section.items}
            center={true}
          />
        ))}
      </div>
      <div className="grid-element">
        {menu.center.map((section, item) => (
          <MenuListing
            key={item}
            title={section.title}
            items={section.items}
            center={true}
          />
        ))}
      </div>
      <div className="grid-element">
      <div className="grid-element">
        {menu.right.map((section, item) => (
          <MenuListing
            key={item}
            title={section.title}
            items={section.items}
            center={true}
          />
        ))}
      </div>      </div>
    </div>
  );
};

export default ABMcAllisterMenu;
